<template>
  <ion-page>
    <Header>
    </Header>
    <ion-content>
    <ion-grid>
        <ion-row>
    <ion-col sizeSm="12" sizeMd="7" sizeLg="8" class="ion-padding-start ion-padding-end">
      <form @submit.prevent="submitForm"></form>
      <ion-item lines="none" class="ion-no-padding">
        <ion-label position="stacked">Email</ion-label>
        <ion-input id="email" v-model.trim="email"></ion-input>
      </ion-item>
      <p v-if="!formIsValid" class="ion-margin-start"><em>Please enter a valid email</em></p>

      <ion-button v-if="state.layout === 'mobile'" @click="submitForm" expand="full" size="large" class="ion-margin-top"><strong>Reset Password</strong></ion-button>
      <ion-button v-if="state.layout === 'desktop'" @click="submitForm" size="large" class="ion-margin-top"><strong>Reset Password</strong></ion-button>

      <ion-button @click="switchSignIn" fill="clear">Login/Signup</ion-button>
      <ion-progress-bar type="indeterminate" v-show="isLoading"></ion-progress-bar>


      <ion-toast
          :is-open=isLoading
          message="Authenticating..."
      ></ion-toast>
      <ion-toast
          :is-open=isError
          :message=error
      >
      </ion-toast>
          </ion-col>
           </ion-row>
      </ion-grid>
    </ion-content>


  </ion-page>
</template>

<script>
import {IonPage, IonItem, IonLabel, IonContent, IonInput, IonButton, IonProgressBar, IonToast} from '@ionic/vue'
import Header from "@/components/global/Header";
import store from '../store'
import auth from "@/mixins/auth";
import router from "@/router";

export default {
  name: "Login",
  components: {IonPage, IonItem, IonLabel, IonContent, IonInput, IonButton, IonProgressBar, IonToast, Header},
  mixins: [auth],
  data() {
    return {
      name: '',
      email: '',
      password: '',
      formIsValid: true,
      mode: 'login',
      isLoading: false,
      isError: false,
      error: null,
      state: store.state,
    }
  },
  async created() {
    const loggedIn = await this.isLoggedIn;
    if (loggedIn) {
      await router.replace("/tabs/app");
    }
  },
  methods: {
    async submitForm() {
      if (this.email === '' || !this.email.includes('@')) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;

      try {
        this.isError = false;

        await this.passwordReset({
          email: this.email,
        }).then(() => {
          router.push('/login');
        })

      } catch (err) {
        this.isError = true;
        this.error = err.message || 'Failed to send reset email';
        console.log(this.error);
      }
      this.isLoading = false;
    },
    switchSignIn() {
      router.push('/login')
    }
  }
}
</script>

<style scoped>

</style>
